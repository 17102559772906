import {Model} from './model'

export class Report extends Model {
	constructor ( data ) {
		super( data );

		if ( _.isNil(data) ) { return }
        
		_.assignIn(this, {
			fileName            : data.fileName,
			dateBy 				: data.fileName.match(/\d{4}-\d{2}-\d{2}/)[0]
		});
	}
}