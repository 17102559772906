import api from '@/api/modules/auth'
import {STORAGE} from '@/api/storage'
import {User} from '@/models/user'
import {Model} from '@/models/model'
import * as store from '../store'
import { UserFabric } from '@/fabrics/UserFabric'
import { setRegisterdAt } from '@/constants/config'
import moment from 'moment-timezone'
import { isDemo, defaultColor } from '@/constants/config'

let demoUser = {"@context":"\/contexts\/Merchant","@id":"\/merchants\/1027942611","@type":"Merchant","fio":"Андреоли Софи Сергеевна","emailPersonal":"amelie017@gmail.com","phone":"+7 (911) 741-41-11","country":"RUS","holdDuration":5,"isLicenceAccepted":0,"profitInPercents":80,"email":"5142453934+merchant@paywall.pw","username":"Sofia","roles":["ROLE_MERCHANT","ROLE_USER"],"status":"ACTIVE","tgId":"5142453934","tgUsername":"sofia","gaClientId":"1932594697.1649661501","ymIdPassedAt":"2022-04-11T13:53:27+00:00","avatar":{"@id":"\/images\/1429226127","@type":"Image","contentUrl":"\/images\/Cw\/CwVRM0bEn9.jpg"},"registeredAt":"2022-04-11T13:53:25+00:00"};

const state = _.defaults({
	me		: isDemo ? new User(demoUser) : null,
}, store.state);

const getters = _.defaults({
	me: (state) => _.isNil(state.me) ? false : state.me,
	hasToken: () => STORAGE.hasToken(),
	hasTG: () => STORAGE.hasTG(),
}, store.getters);

const actions = {
	/* START AUTH */
	async login ({ commit }, data ) {
		commit(store.START_REQUEST);

		try {
			let res = await api.auth( data );

			STORAGE.set(res.data);

			commit(store.PENDING, false);
		} catch (err) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			commit('logged', null);
			commit('error', store.parseError(err));
			store.logError(err);

			STORAGE.clearMe();

			return Promise.reject( store.parseError(err) );
		}
	},

	async logout ({ commit }) {
		let themeColor = localStorage.getItem('themeColor') ? localStorage.getItem('themeColor') : defaultColor;
		STORAGE.clear();
		localStorage.setItem('themeColor', themeColor);

		commit('logged', null);
		commit(store.PENDING, false);
		commit('error', null);
		commit(store.PENDING, false);

		return true;
	},

	async signup ({ commit }, data ) {
		commit(store.START_REQUEST);

		try {
			let res = await api.signup( data );
			return UserFabric.initUser(res.data);
		} catch (err) {
			commit(store.PENDING, false);
			commit(store.FAILURE, store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},

	async social ({ commit }, data ) {
		commit(store.START_REQUEST);

		try {
			let res = await api.authSocial( data );

			STORAGE.set(res.data);

			commit(store.PENDING, false);
		} catch (err) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			commit('logged', null);
			store.logError(err);
commit('error', store.parseError(err));
			STORAGE.clear();

			return Promise.reject( store.parseError(err) );
		}
	},

	async signupDev ({ commit }, data ) {
		commit(store.START_REQUEST);

		try {
			let res = await api.signupDev( data );
			commit(store.PENDING, false);
			return new User(res.data);
		} catch (err) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			commit('logged', null);
			store.logError(err);
commit('error', store.parseError(err));
			STORAGE.clear();

			return Promise.reject( store.parseError(err) );
		}
	},

	async me ({ commit }, data ) {
		commit(store.START_REQUEST);

		if ( isDemo ) {
			let v = demoUser;
			commit('logged', v);
			STORAGE.setUser(v);
			return;
		}

		commit(store.PENDING, true);
		try {
			let me = await api.me();

			if ( !(data && data.noSave) ) {
				commit('logged', me.data);
				STORAGE.setUser(me.data);
			}

			commit(store.PENDING, false);
			return me.data;
		} catch (err) {
			let e = store.parseError(err);

			commit(store.FAILURE, e);
			commit(store.PENDING, false);
			commit('logged', null);
			store.logError(err);
			commit('error', store.parseError(err));
			STORAGE.clear();

			return Promise.reject( e );
		}
	},

	async qr ({ commit }, data ) {
		commit(store.START_REQUEST);

		commit(store.PENDING, false);
		try {
			let qr = await api.qr(data);
			STORAGE.setQRId(qr.headers.authrequestid);
			STORAGE.setQRLink(qr.headers.authlink);
			return new Promise((resolve, _) => {
				const reader = new FileReader();
				reader.onloadend = () => resolve(reader.result);
				reader.readAsDataURL(new Blob([qr.data]));
			});
		} catch (err) {
			let e = store.parseError(err);

			commit(store.FAILURE, e);
			commit(store.PENDING, false);
			commit('logged', null);
			store.logError(err);
			commit('error', store.parseError(err));
			STORAGE.clear();

			return Promise.reject( e );
		}
	},

	async qrLogin ({ commit }, data ) {
		commit(store.START_REQUEST);

		commit(store.PENDING, false);
		try {
			let res = await api.qrLogin(data.id);
			return res.data;
		} catch (err) {
			let e = store.parseError(err);

			commit(store.FAILURE, e);
			commit(store.PENDING, false);
			commit('logged', null);
			store.logError(err);
			commit('error', store.parseError(err));
			STORAGE.clear();

			return Promise.reject( e );
		}
	},

	async meEdit ({ commit }, data ) {
		commit(store.START_REQUEST);
		let onlySendRequestWithoutEditingStoreData = data.tgBotAddResource ? true : false;

		try {
			let me 	= await api.meEdit(data);
			if ( !onlySendRequestWithoutEditingStoreData ) {
				commit('logged', me.data);
				STORAGE.setUser(me.data);
			}
			commit(store.PENDING, false);
		} catch (err) {
			commit(store.PENDING, false);
			commit(store.FAILURE, err);
			store.logError(err);
commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},

	async pageView ({state, commit}, data) {
		commit(store.START_REQUEST);

		try {
			let res = await api.pageView(data);
			commit(store.PENDING, false);
			return new Model(res.data);
		} catch (err) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},
}

const mutations = _.defaults({
	logged ( state, v ) {
		if ( v == null ) {
			state.me = null;
			return;
		}

		state.me = UserFabric.initUser(v);
		setRegisterdAt(state.me.registeredAt);
	},

	token ( state, v ) {
		STORAGE.set({token: v});
	}
}, store.mutations);

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
