export const PENDING 		= 'PENDING';
export const FAILURE 		= 'error';
export const START_REQUEST 	= 'START_REQUEST';
export const CLEAR_ITEMS 	= 'CLEAR_ITEMS';
export const TOKEN_EXPIRED 	= 'TOKEN_EXPIRED';

export const state 			= {
	pending 		: false,
	error 			: false,
}

export const getters 		= {
	isPending				: state => state.pending,
	error					: state => state.error,
}

export const mutations 		= {
	[PENDING] ( state, value ) {
		state.pending = value;
	},
	[FAILURE] ( state, value ) {
		state.error = value;
	},
	[START_REQUEST] ( state ) {
		state.pending = true;
		state.error = false;
	},
	[CLEAR_ITEMS] ( state ) {
		state.items = [];
	},
	
}

export function parseError ( err ) {
	let error = err.toString();

	if ( _.isObject(err) && _.isObject(err.response) && _.isObject(err.response.data) && err.response.data.detail ) { return err.response.data.detail; }
	if ( _.isObject(err) && _.isObject(err.response) && _.isObject(err.response.data) && err.response.data['hydra:description'] ) { return err.response.data['hydra:description']; }
	if ( _.isObject(err) && _.isObject(err.response) && _.isObject(err.response.data) && err.response.data.error_description ) { return err.response.data.error_description; }
	if ( _.isObject(err) && _.isObject(err.response) && _.isObject(err.response.data) && err.response.data.message ) {
		if ( err.response.data.message == 'Invalid JWT Token' || err.response.data.message == 'Expired JWT Token' || err.response.data.message == 'JWT Token not found' ) {
			if ( window.location.pathname.replace(/\//g, '') != 'login' ) {
				window.location.href = '/login';
			}

			return TOKEN_EXPIRED;
		}

		return err.response.data.message;
	}

	return error;
}

export function logError ( err ) {
	if ( _.isObject(err) && _.isObject(err.response) && _.isObject(err.response.data) && err.response.data.detail ) {
		console.log("ERROR RESPONSE DETAIL: ", err.response.data.detail);
	}
	if ( _.isObject(err) && _.isObject(err.response) && _.isObject(err.response.data) && err.response.data['hydra:description'] ) {
		console.log("ERROR RESPONSE HYDRA DESCRIPTION: ", err.response.data['hydra:description']);
	}
	if ( _.isObject(err) && _.isObject(err.response) && _.isObject(err.response.data) && err.response.data.error_description ) {
		console.log("ERROR RESPONSE DESCRIPTION: ", err.response.data.error_description);
	}

	if ( _.isObject(err) && _.isObject(err.config) && _.isObject(err.config.headers) )
		_.each(err.config.headers, (v, i) => console.log(i, ' : ', v));
	
	console.log("ERROR RESPONSE: ", err.response);
}