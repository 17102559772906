import _ from 'lodash'
import {Model} from './model'
import { APIURL } from '@/api/index'

export class CImage extends Model {
	constructor ( data ) {
		super( data );
		
		if ( _.isNil(data) ) return

		this.category = data.category;
		
		if ( data.contentUrl ) {
			this.contentUrl = APIURL.slice(0, -1) + data.contentUrl;
		}
	}

	static BGColor (s, l, title) {
		let hash = 0;

		if ( !title ) return;

		for (let i = 0; i < title.length; i++) {
			hash = title.charCodeAt(i) + ((hash << 5) - hash);
		}

		let h = hash % 360;
		return 'background: hsl('+h+', '+s+'%, '+l+'%)'; 
	}
}

export const IMAGES_CATEGORIES = [
	{
		id: 'EDUCATION',
		title: 'create.dropdown[1]'
	},
	{
		id: 'CUISINE',
		title: 'create.dropdown[2]'
	},
	{
		id: 'FINANCE',
		title: 'create.dropdown[3]'
	},
	{
		id: 'FASHION',
		title: 'create.dropdown[4]'
	},
	{
		id: 'MAKEUP',
		title: 'create.dropdown[5]'
	},
	{
		id: 'CRYPTOCURRENCY',
		title: 'create.dropdown[6]'
	},
	{
		id: 'ANIMALS',
		title: 'create.dropdown[7]'
	},
	{
		id: 'CATS',
		title: 'create.dropdown[8]'
	},
	{
		id: 'DOGS',
		title: 'create.dropdown[9]'
	},
	{
		id: 'GIRLS',
		title: 'create.dropdown[10]'
	},
	{
		id: 'SPORT',
		title: 'create.dropdown[11]'
	},
	{
		id: 'NATURE',
		title: 'create.dropdown[12]'
	},
	{
		id: 'NEUTRAL',
		title: 'create.dropdown[13]'
	},
]