import {Model} from './model'

export class Comment extends Model {
	constructor ( data ) {
		super( data );

		if ( _.isNil(data) ) { return }
        
		_.assignIn(this, data);
	}
}