import api from '@/api/modules/resources'
import * as store from '../store'
import {Resource} from '@/models/resource'

const state = {
	edited 			: null,
    channels 		: [],
    groups          : [],
	openModalRemove	: false,
}

const getters = _.defaults({
	openModalRemove		: state => state.openModalRemove,
	edited				: state => state.edited,
    channels			: state => state.channels.length > 0 ? state.channels : [],
    groups	    		: state => state.groups.length > 0 ? state.groups : [],
}, store.getters);

const actions = {
	async channels ({state, commit}, data) {
		commit(store.START_REQUEST);

		try {
			let res = await api.channels(data);
			commit('channels', res.data['hydra:member']);
			commit(store.PENDING, false);
			return state.channels;
		} catch (err) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
    },

    async groups ({state, commit}, data) {
		commit(store.START_REQUEST);

		try {
			let res = await api.groups(data);
			commit('groups', res.data['hydra:member']);
			commit(store.PENDING, false);
			return state.groups;
		} catch (err) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},

	async channelId ({state, commit}, data) {
		commit(store.START_REQUEST);

		try {
			let res = await api.channelId(data);
			commit(store.PENDING, false);
			return new Resource(res.data);
		} catch (err) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
    },

    async groupId ({state, commit}, data) {
		commit(store.START_REQUEST);

		try {
			let res = await api.groupId(data);
			commit(store.PENDING, false);
			return new Resource(res.data);
		} catch (err) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
    },

	async channel_check ({state, commit}, data) {
		commit(store.START_REQUEST);

		try {
			let res = await api.channel_check(data);
			commit(store.PENDING, false);
			return new Resource(res.data);
		} catch (err) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
    },

    async group_check ({state, commit}, data) {
		commit(store.START_REQUEST);

		try {
			let res = await api.group_check(data);
			commit(store.PENDING, false);
			return new Resource(res.data);
		} catch (err) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
    },

	async remove ({state, commit}, data) {
		commit(store.START_REQUEST);

		try {
			let method = data.type.toLowerCase() == 'group' ? api.removeGroup : api.removeChannel;

			let res = await method(data);
			commit(store.PENDING, false);
			return true;
		} catch (err) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
    },
}

const mutations = _.defaults({
	edited ( state, i ) {
		state.edited = i;
	},

	add ( state, i ) {
		state.channels.push(i);
	},

	channels ( state, channels ) {
		state.channels = _.map(channels, i => i instanceof Resource ? i : new Resource(i));
    },

    groups ( state, groups ) {
		state.groups = _.map(groups, i => i instanceof Resource ? i : new Resource(i));
	},

	remove ( state, i ) {
		_.remove(state.channels, v => v.id == i.id);
		_.remove(state.groups, v => v.id == i.id);

		state.channels = _.cloneDeep(state.channels);
		state.groups = _.cloneDeep(state.groups);
	},

	openModalRemove( state, i) {
		state.openModalRemove = i;
	},

	edit ( state, i ) {
		let indexc = _.findIndex(state.channels, v => v.id == i.id);
		let indexg = _.findIndex(state.groups, v => v.id == i.id);

		if ( indexc != -1 ) {
			state.channels[indexc] = i;
			state.channels = _.cloneDeep(state.channels);
		}
		if ( indexg != -1 ) {
			state.groups[indexg] = i;
			state.groups = _.cloneDeep(state.groups);
		}
	},

}, store.mutations);

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}
